import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import LogoFile from "../images/LOGO_LONG.svg";

const StyledFooter = styled.footer``;
const FooterHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
`;
const FooterLogo = styled.img`
  width: 300px;
  padding: 1em 0 0.5em 0;
  margin: 0;
  @media screen and (max-width: 991px) {
    margin: 0 2.5%;
  }
`;
const FooterContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const FooterBlock = styled.div`
  padding: 30px 0;
  flex: ${props => (props.flex ? props.flex : 1)};
  @media screen and (max-width: 991px) {
    flex-basis: 100%;
    padding: 5%;
  }
`;
const Text = styled.h5`
  margin: 0;
  line-height: 1.6;
  letter-spacing: 1.2px;
  opacity: 0.67;
  & > b {
    font-size: 1.1em;
    color: black;
    opacity: 1 !important;
  }
`;
const FooterLink = styled(Link)`
  margin: 0;
  line-height: 1.6;
  letter-spacing: 1.2px;
  font-size: 1.2em;
  display: block;
  font-family: "Raleway";
  font-weight: 700;
  text-decoration: none;
  color: #3f6564;
  opacity: 0.87;
  text-transform: uppercase;
`;
const LinkWrapper = styled.div`
  display: inline-block;
  float: right;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    & > * {
      flex: 50%;
      margin: 0 auto;
    }
  }
`;
const FooterText = styled.p`
  padding: 0 1em;
  line-height: 1.6;
  font-size: 0.9em;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #3f6564;
  @media screen and (max-width: 991px) {
    padding: 1em 0;
    margin: 0;
  }
`;
const Footer = () => (
  <StyledFooter>
    <FooterHeader>
      <FooterLogo src={LogoFile} alt={"Vive Advokater lang logo"} />
    </FooterHeader>
    <FooterContent>
      <FooterBlock flex={2}>
        <FooterText>
          Vi§Vé Advokater bistår våre klienter med å finne praktiske løsninger
          som er godt juridisk forankret. Vi har bred og variert erfaring fra
          mange rettsområder med særlig kompetanse som bistandsadvokat og i
          saker vedrørende barn, familie, arbeids - og utlendingsrett.
        </FooterText>
      </FooterBlock>
      <FooterBlock flex={2}>
        <Text>
          <b>Postadresse:</b> Kirkegata 1-3, 0153 Oslo
        </Text>
        <Text>
          <b>Besøksadresse:</b> Kirkegata 1-3, 0153 Oslo
        </Text>
        <Text>
          <b>E-post:</b> gv@viveadvokater.no
        </Text>
        <Text>
          <b>Mobil:</b> 924 68 710
        </Text>
        <Text>
          <b>Organisasjonummer:</b> 915 169 228
        </Text>
      </FooterBlock>
      <FooterBlock
        flex={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <LinkWrapper>
          <FooterLink to={"/"}>Hjem</FooterLink>
          <FooterLink to={"/blogg/"}>Nyheter</FooterLink>
          <FooterLink to={"/fagområder/"}>Fagområder</FooterLink>
          <FooterLink to={"/priser-og oppdragsbetingelser/"}>Priser</FooterLink>
          <FooterLink to={"/lenker/"}>Lenker</FooterLink>
          <FooterLink to={"/kontakt/"}>Kontakt</FooterLink>
        </LinkWrapper>
      </FooterBlock>
    </FooterContent>
  </StyledFooter>
);
export default Footer;
