/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  orgImg,
}) => {
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      url: "https://viveadvokater.no/",
      logo: orgImg,
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: "https://viveadvokater.no/",
    },
  ];

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          "@type": "BlogPosting",
          url,
          name: title,
          headline: title,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          author: schemaOrgJSONLD[0],
          publisher: schemaOrgJSONLD[0],
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": "https://viveadvokater.no/",
          },
          datePublished,
        },
      ]
    : schemaOrgJSONLD;
};

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  isblog,
  url,
  date,
}) {
  console.log({
    description,
    lang,
    meta,
    keywords,
    title,
    image,
    isblog,
    url,
    date,
  });
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        logo: file(relativePath: { eq: "cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const JSONLD = JSON.stringify(
    getSchemaOrgJSONLD({
      isBlogPost: isblog,
      url: url || site.siteMetadata.siteUrl,
      title,
      image: image || logo.childImageSharp.fluid.src,
      description: metaDescription,
      datePublished: date,
      orgImg: logo.childImageSharp.fluid.src,
    })
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `https://viveadvokater.no${
            image ? image : logo.childImageSharp.fluid.src
          }`,
        },
        {
          property: `image`,
          content: `https://viveadvokater.no${
            image ? image : logo.childImageSharp.fluid.src
          }`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: isblog ? `article` : `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "VI§VE Advokater",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      script={[
        {
          type: "application/ld+json",
          innerHTML: JSONLD,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `nb`,
  meta: [],
  keywords: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
