import { Link } from "gatsby";
import React, { useState } from "react";
import LogoFile from "../images/LOGO.svg";
import styled, { css } from "styled-components";

const StyledHeader = styled.header`
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #efefef;
  font-family: "Darker Grotesque";
  z-index: 999;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
`;
const UL = styled.ul`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > :first-child {
    flex: 5;
    justify-content: flex-start;
    & > * {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
const SMALLUL = styled.ul`
  display: none;
  width: 100%;
  height: 100%;
  max-width: 991px;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
  }
`;
const LI = styled.li`
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media screen and (max-width: 991px) {
    flex: initial;
  }
`;
const NavLink = styled(Link)`
  height: auto;
  text-decoration: none;
  letter-spacing: 1px;
  color: #1e1d22;
  ${props =>
    props.CTA
      ? css`
          padding: 6px 25px;
          color: white;
          background: #1e1d22;
        `
      : null}
  @media screen and (max-width: 991px) {
    font-size: 1.5em;
    font-weight: 500;
  }
`;
const Logo = styled.img`
  height: 50px;
  margin: auto !important;
`;
const DropDown = styled.ul`
  position: fixed;
  background: #efefef;
  height: calc(100vh - 80px);
  top: 80px;
  opacity: ${props => (props.open ? "1" : "0")};
  pointer-events: ${props => (props.open ? "initial" : "none")};
  z-index: 10;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom:25%;
`;
const Menu = styled.svg`
  height: 1.3em;
  margin: 0 1.3em;
  & > * {
    transition: all 0.3s ease;
  }
`;
const Header = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <StyledHeader>
      <UL>
        <LI>
          <NavLink to="/">
            <Logo
              src={LogoFile}
              alt="Vi§VÉ | VIVE"
              type="image/svg+xml"
              draggable={false}
            />
          </NavLink>
        </LI>
        <LI>
          <NavLink to="/">HJEM</NavLink>
        </LI>
        <LI>
          <NavLink to="/blogg/">NYHETER</NavLink>
        </LI>
        <LI>
          <NavLink to="/priser-og oppdragsbetingelser/">PRISER</NavLink>
        </LI>
        <LI>
          <NavLink to="/fagområder/">FAGOMRÅDER</NavLink>
        </LI>
        <LI>
          <NavLink to="/lenker/">LENKER</NavLink>
        </LI>
        <LI>
          <NavLink to="/kontakt/" CTA={true}>
            KONTAKT
          </NavLink>
        </LI>
      </UL>
      <SMALLUL>
        <LI>
          <NavLink to="/">
            <Logo
              src={LogoFile}
              alt="Vi§VÉ | VIVE"
              type="image/svg+xml"
              draggable={false}
            />
          </NavLink>
        </LI>
        <LI>
          <Menu
            viewBox="0 0 40 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen(!isOpen)}
            alt={"meny"}
          >
            <rect
              width={isOpen ? "30" : "40"}
              x={isOpen ? "5" : "0"}
              height="6"
              y={isOpen ? "6" : "0"}
              fill="black"
            />
            <rect
              y={isOpen ? "6" : "12"}
              x={isOpen ? "5" : "0"}
              width={isOpen ? "30" : "40"}
              height="6"
              fill="black"
            />
          </Menu>
        </LI>
      </SMALLUL>
      <DropDown open={isOpen}>
        <LI>
          <NavLink to="/">HJEM</NavLink>
        </LI>
        <LI>
          <NavLink to="/blogg/">NYHETER</NavLink>
        </LI>
        <LI>
          <NavLink to="/fagområder/">PRISER</NavLink>
        </LI>
        <LI>
          <NavLink to="/priser-og oppdragsbetingelser/">FAGOMRÅDER</NavLink>
        </LI>
        <LI>
          <NavLink to="/lenker/">LENKER</NavLink>
        </LI>
        <LI>
          <NavLink to="/kontakt/" CTA={true}>
            KONTAKT
          </NavLink>
        </LI>
      </DropDown>
    </StyledHeader>
  );
};

export default Header;
